import type { WidgetDescriptor as ExternalWidgetDescriptor } from '@aurora/external-types/feature';
import type { WidgetLocation } from '@aurora/shared-client/components/context/SectionWidgetContext';
import type { WidgetContextInterface } from '@aurora/shared-client/components/context/WidgetContext';
import { QuiltWrapperWidgetCategory, WidgetCategory } from '@aurora/shared-client/types/enums';
import { Groups } from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  QuiltComponent,
  QuiltWrapper
} from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  ComponentResolveServerFragment,
  QuiltFragment
} from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { deepClone, enumFromValue } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { getLog } from '@aurora/shared-utils/log';
import type { WidgetDescriptor } from '../../components/common/Widget/types';
import type { WidgetInfo } from '../../components/common/Widget/WidgetRegistry';
import {
  CommonWidgetRegistry,
  WidgetRegistry
} from '../../components/common/Widget/WidgetRegistry';
import type { QuiltWrapperWidget } from '../../components/pageeditor/QuiltWrapperRenderer/types';
import { QuiltWrapperSectionType } from '../../components/pageeditor/QuiltWrapperRenderer/types';
import endUserComponentRegistry from '../../features/endUserComponentRegistry';
import type { BannerWidgetData, SectionWidgetData } from './PageEditorHelper';
import { isPageEditorBannerWidget, isPageEditorSectionWidget } from './PageEditorHelper';
import { getQuiltSectionsData, updateQuiltSections, updateSections } from './SectionHelper';

const log = getLog(module);

// We should be able to just use the `WidgetType` interface, but the code in this file currently knows too much about widget implementations. TODO
type AllowedWidgetTypes = SectionWidgetData | BannerWidgetData;

/**
 * Removes a widget from the section based on its location.
 * @param quilt the quilt the section belongs to.
 * @param sectionId the location sectionId of widget to be removed on quilt.
 * @param columnId the location columnId of widget to be removed on quilt.
 * @param widgetIdx the index of widget to be removed on quilt.
 */
export function removeWidget(
  quilt: QuiltFragment,
  sectionId: string,
  columnId: string,
  widgetIdx: number
): QuiltFragment {
  const quiltClone = deepClone(quilt);
  const { sections, position: sectionListIdx } = getQuiltSectionsData(quiltClone);

  const currentSection = sections.find(section => section.id === sectionId);

  if (currentSection) {
    currentSection.columnMap[columnId] = currentSection.columnMap[columnId].filter(
      (widget, idx) => idx !== widgetIdx
    );
  }

  if (sections) {
    return updateSections(quiltClone, sections, sectionListIdx);
  }

  return quilt;
}

/**
 * Updates the props for the specified widget.
 *
 * @param quilt the containing quilt
 * @param widgetData data for the selected section or page header widget
 * @param widgetProps the updated props for the widget.
 */
export function updateWidget(
  quilt: QuiltFragment,
  widgetData: AllowedWidgetTypes,
  widgetProps: unknown
): QuiltFragment {
  const quiltClone = deepClone(quilt);
  if (isPageEditorSectionWidget(widgetData)) {
    const { sectionId, columnId, widgetIdx } = widgetData.location;
    const { sections } = getQuiltSectionsData(quiltClone);
    if (sections) {
      sections.find(section => section.id === sectionId).columnMap[columnId][widgetIdx].props =
        widgetProps;
      return updateQuiltSections(quiltClone, sections);
    }
  } else if (isPageEditorBannerWidget(widgetData)) {
    const {
      container: { headerComponentProps }
    } = quiltClone;

    //If there is no default values, headerComponentProps will not be defined
    //so we need to create it and add it to the quilt.
    if (!!headerComponentProps) {
      headerComponentProps[widgetData.widgetId] = widgetProps;
      quiltClone.container['headerComponentProps'] = headerComponentProps;
    } else {
      const tempHeaderProps = {
        headerComponentProps: {
          [widgetData.widgetId]: widgetProps
        }
      };
      quiltClone.container = { ...quiltClone.container, ...tempHeaderProps };
    }

    return quiltClone;
  }

  return quilt;
}

/**
 * Updates the props for the specified widget.
 *
 * @param quiltWrapper the containing quilt wrapper
 * @param widgetData data for the selected section or page header widget
 * @param widgetProps the updated props for the widget.
 */
export function updateQuiltWrapperWidget(
  quiltWrapper: QuiltWrapper,
  widgetData: QuiltWrapperWidget,
  widgetProps: unknown
): QuiltWrapper {
  const quiltWrapperClone = deepClone(quiltWrapper);
  const {
    location: { sectionId, widgetIdx }
  } = widgetData;
  quiltWrapperClone[sectionId].items[widgetIdx].props = widgetProps;

  return quiltWrapperClone;
}

/**
 * Returns the widgets that are allowed to be added to the quilt wrapper based on the specified category
 *
 * @param widgetCategory the target WidgetCategory
 * @param sectionType wrapper section type
 * @param items current items in the wrapper
 */
function getAllowedQuiltWrapperWidgetsForCategory(
  widgetCategory: QuiltWrapperWidgetCategory,
  sectionType: QuiltWrapperSectionType,
  items: QuiltComponent[]
): Record<string, WidgetInfo> {
  if (widgetCategory === QuiltWrapperWidgetCategory.COMMON) {
    switch (sectionType) {
      case QuiltWrapperSectionType.HEADER: {
        const itemsHasNavbar = items.some(
          component => component.id === EndUserComponent.NAVBAR_WIDGET
        );
        const itemsHasBannerWidget = items.some(
          component => component.id === EndUserComponent.BANNER_WIDGET
        );
        return Object.fromEntries(
          Object.entries(CommonWidgetRegistry).filter(([id]) => {
            if (itemsHasNavbar && id == EndUserComponent.NAVBAR_WIDGET) {
              return false;
            }

            if (itemsHasBannerWidget && id == EndUserComponent.BANNER_WIDGET) {
              return false;
            }
            return true;
          })
        );
      }
      case QuiltWrapperSectionType.FOOTER: {
        return Object.fromEntries(
          Object.entries(CommonWidgetRegistry).filter(([id]) => {
            return id !== EndUserComponent.NAVBAR_WIDGET && id !== EndUserComponent.BANNER_WIDGET;
          })
        );
      }
    }
    return Object.fromEntries(Object.entries(CommonWidgetRegistry));
  }
  return {};
}

/**
 * User defined type guard for QuiltWrapperWidgetCategory
 *
 * @param category the category
 */
function isQuiltWrapperWidgetCategory(category: string): category is QuiltWrapperWidgetCategory {
  return Object.values(QuiltWrapperWidgetCategory).includes(category as QuiltWrapperWidgetCategory);
}

/**
 * Returns all allowed widgets for a specific category at quilt wrapper level.
 * The category may be specified by either providing the category directly or by providing a widget id.
 *
 * In the instance that a widget id is provided, all widgets in the same category as the widget are returned
 *
 * @param identifier the widget category or widget id
 * @param wrapperSectionType wrapper section type
 * @param items current items in the wrapper
 */
export function getAllowedQuiltWrapperWidgets(
  identifier: QuiltWrapperWidgetCategory,
  wrapperSectionType: QuiltWrapperSectionType,
  items: QuiltComponent[]
): Record<string, WidgetInfo> {
  if (isQuiltWrapperWidgetCategory(identifier)) {
    return getAllowedQuiltWrapperWidgetsForCategory(identifier, wrapperSectionType, items);
  } else if (!WidgetRegistry[identifier]) {
    log.error(
      '%s does not exist as either a widget in the WidgetRegistry or a widget category',
      identifier
    );
    return {};
  } else {
    const { category: targetCategory } = WidgetRegistry[identifier];
    return getAllowedQuiltWrapperWidgetsForCategory(targetCategory, wrapperSectionType, items);
  }
}

/**
 * Returns the namespace for a widget
 *
 * As an example, a widget with the id 'messages/MessageListByUserWidget/MessageListByUserWidget'
 * will be returned as '/components/messages/MessageListByUserWidget'
 *
 * @param widgetId the widget id
 */
export function getNamespaceForWidget(widgetId: string): string {
  const regex = /\/[^/]*$/g;
  return `/components/${widgetId.replaceAll(regex, '')}`;
}

/**
 * Returns a semi-unique id for the widget that can be used as a query param key.
 *
 * As an example, widget data with columnId, widgetIdx and widgetId passed will have a string returned as
 * <widgetName><delimiter>[<suffix><delimiter>]<columnId><delimiter><sectionId><widgetIdx>
 *
 * @param widgetData the widget's details.
 * @param widgetLocation location of the widget within the section
 * @param suffix optional suffix to add to the unique id.
 * @param delimiter delimiter to use.
 */
export function getQueryParamKeyForWidget(
  widgetData: WidgetContextInterface,
  widgetLocation: WidgetLocation,
  suffix: string = null,
  delimiter = '-'
): string | null {
  const items: string[] = [];
  const { id } = widgetData ?? {};

  const widgetName = id?.split('/')?.pop();

  items.push(widgetName, suffix);

  if (widgetLocation) {
    const { columnId, widgetIdx, sectionId } = widgetLocation;
    items.push(columnId, sectionId, widgetIdx.toString());
  }

  return items
    .filter(item => item != null)
    .join(delimiter)
    .toLowerCase();
}

/**
 * returns EndUserWidgetDescriptor if it is one
 * @param descriptor the descriptor to check
 */
export function isEndUserWidgetDescriptor(
  descriptor: ExternalWidgetDescriptor
): descriptor is WidgetDescriptor {
  const { id } = descriptor;
  const endUserComponentId = enumFromValue(EndUserComponent, id);
  return endUserComponentId !== null;
}

/**
 * Returns classname to apply to wrapper of widget
 * @param fullHeight if the widget is to render full height
 */
export function getWidgetClass(fullHeight: boolean): string[] {
  const widgetClassNames: string[] = ['lia-g-section-widget'];
  if (fullHeight) {
    widgetClassNames.push('lia-g-page-full-height');
  }

  return widgetClassNames;
}

export function hasEditForm(componentId: string) {
  const widgetDescriptor = endUserComponentRegistry.getWidgetDescriptor(componentId);
  if (widgetDescriptor && isEndUserWidgetDescriptor(widgetDescriptor)) {
    return !!widgetDescriptor?.editor?.form || !!widgetDescriptor?.getConfigurationSpec;
  }
  return false;
}

export function hasQuiltWrapperEditForm(componentId: string) {
  const widgetDescriptor = endUserComponentRegistry.getWidgetDescriptor(componentId);
  if (widgetDescriptor && isEndUserWidgetDescriptor(widgetDescriptor)) {
    return !!widgetDescriptor?.editor?.quiltWrapperForm || !!widgetDescriptor?.getConfigurationSpec;
  }
  return false;
}

export function isCustomComponent(componentId: string) {
  return componentId.startsWith('custom.widget');
}

/**
 * Return the widget category for a given component template based on grouping
 *
 * @param component the component resolve
 */
export function getWidgetCategoryFromComponentTemplate(
  component: ComponentResolveServerFragment
): WidgetCategory {
  if (!component) {
    return null;
  }

  const { grouping } = component.template;
  return grouping === Groups.Custom
    ? WidgetCategory.CUSTOM_CONTENT
    : grouping === Groups.Content
    ? WidgetCategory.CONTENT
    : grouping === Groups.People
    ? WidgetCategory.PEOPLE
    : grouping === Groups.Places
    ? WidgetCategory.PLACES
    : grouping === Groups.Texthtml
    ? WidgetCategory.CUSTOM_HTML_CONTENT
    : null;
}
