import type {
  CustomComponentProps,
  CustomWidgetProps,
  WidgetProps
} from '../../common/Widget/types';
import type { QuiltWrapperWidgetLocation } from '../../context/QuiltWrapperWidgetContext/QuiltWrapperWidgetLocationContext';

export enum QuiltWrapperSectionType {
  HEADER = 'header',
  FOOTER = 'footer'
}

export interface QuiltWrapperWidget {
  /**
   * The id of the widget to be rendered
   */
  widgetId: string;
  /**
   * The location of the widget to be rendered
   */
  location: QuiltWrapperWidgetLocation;
  props?: WidgetProps | CustomWidgetProps | CustomComponentProps;
}
